import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  readonly INTERVAL_CHECK = 180000;

  private updateAvailableSubject = new BehaviorSubject<Object>({
    subject: false,
    version: '',
  });

  public updateAvailable$ = this.updateAvailableSubject.asObservable();
  dialogService = inject(DialogService);

  constructor(private http: HttpClient) {
    this.initVersionCheck();
  }

  initVersionCheck() {
    interval(this.INTERVAL_CHECK).subscribe(() => this.checkForUpdates());
  }

  checkForUpdates() {
    this.http
      .get<{ version: string }>(
        `assets/version.json?timestamp=${new Date().getTime()}`
      )
      .subscribe((response) => {
        if (localStorage.getItem('appVersion') != null) {
          let currentVersion = localStorage.getItem('appVersion');

          if (currentVersion != response.version) {
            this.updateAvailableSubject.next({
              subject: true,
              version: response.version,
            });
          }
        } else {
          localStorage.setItem('appVersion', response.version);
        }
      });
  }
}
